<template>
  <v-container fluid class="content-wrapper" style="overflow-y: hidden">
    <div class="content-body">
      <div class="row match-height">
        <div class="col-12">
          <div class="d-flex flex-row justify-start align-center mb-5">
            <h4 class="fw-600 mb-0">{{ $t("SigninInvoiceAndBank.title") }}</h4>
            <v-btn
              icon
              class="ml-3"
              @click="[(invoiceToActive = true), initAll()]"
              :class="loadingData && 'animate__spining'"
              :disabled="loadingData"
            >
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </div>
        </div>
      </div>

      <div class="row pt-0 mt-0">
        <div v-if="loadingData" class="col-12 pt-0">
          <v-skeleton-loader
            type="card-heading, list-item-two-line, divider, card-heading, list-item-two-line"
          ></v-skeleton-loader>
        </div>
        <div v-else class="col-12 pt-0">
          <div class="shopping-cart">
            <div class="d-flex flex-row mb-5">
              <button
                class="btn-min-width gl-bt ml-0 mr-3"
                :class="invoiceToActive ? 'btn-edit-invoice-active' : 'btn-edit-invoice'"
                type="button"
                @click="[(invoiceToActive = true), initAll()]"
              >
                <span>{{ $t("SigninInvoiceAndBank.invoice") }}</span>
              </button>

              <button
                class="btn-edit-invoice btn-min-width gl-bt mx-0"
                :class="!invoiceToActive ? 'btn-edit-invoice-active' : 'btn-edit-invoice'"
                type="button"
                @click="invoiceToActive = false"
              >
                <span>{{ $t("SigninInvoiceAndBank.bank_details") }}</span>
              </button>
            </div>

            <div class="tab-content pt-1">
              <div
                class="tab-pane active"
                id="shop-cart-tab"
                aria-labelledby="shopping-cart"
                v-if="invoiceToActive"
              >
                <div class="row match-height">
                  <div class="col-md-12 col-12">
                    <div class="card" style="height: auto;">
                      <div class="card-content">
                        <div class="card-body" style="height: auto;">
                          <div class="row">
                            <div class="col-md-6 col-sm-12 col-12">
                              <b>
                                {{
                                  itemInvoiceInfo.type == 1
                                    ? $t("SigninInvoiceAndBank.legal_company_name")
                                    : $t("SigninInvoiceAndBank.fullname")
                                }}
                              </b>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12">
                              {{ itemInvoiceInfo.name || "" }}
                            </div>
                          </div>
                          <hr />
                          <div class="row">
                            <div class="col-md-6 col-sm-12 col-12">
                              <b>{{ $t("SigninInvoiceAndBank.for_att_for") }}</b>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12">
                              {{ itemInvoiceInfo.name || "" }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="checkRoleUserFunc() == 'provider'" class="row match-height">
                  <div class="col-md-12 col-12">
                    <button
                      class="btn btn-info btn-min-width mb-1 gl-bt hover_btn mx-0"
                      type="button"
                      @click="gotoEditInvoiceInfoFunc()"
                    >
                      <span>{{ $t("SigninInvoiceAndBank.edit_invoice") }}</span>
                    </button>
                  </div>
                </div>

                <div class="row match-height">
                  <div class="col-md-12 col-12">
                    <div class="card" style="height: auto;">
                      <div class="card-content">
                        <div class="card-body" style="height: auto;">
                          <div class="row">
                            <div class="col-md-6 col-sm-12 col-12">
                              <b>
                                {{
                                  itemInvoiceInfo.type == 1
                                    ? $t("SigninInvoiceAndBank.company")
                                    : $t("SigninInvoiceAndBank.fullname")
                                }}
                              </b>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12">
                              {{ itemInvoiceInfo.name || "" }}
                            </div>
                          </div>
                          <hr />
                          <div class="row">
                            <div class="col-md-6 col-sm-12 col-12">
                              <b>{{ $t("SigninInvoiceAndBank.address") }}</b>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12">
                              {{ itemInvoiceInfo.address || "" }}
                            </div>
                          </div>
                          <hr />
                          <div class="row">
                            <div class="col-md-6 col-sm-12 col-12">
                              <b>{{ $t("SigninInvoiceAndBank.postal_code") }}</b>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12">
                              {{ itemInvoiceInfo.postcode || "" }}
                            </div>
                          </div>
                          <hr />
                          <div class="row">
                            <div class="col-md-6 col-sm-12 col-12">
                              <b>{{ $t("SigninInvoiceAndBank.city") }}</b>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12">
                              {{ checkProvinceFunc() }}
                            </div>
                          </div>
                          <hr />
                          <div class="row">
                            <div class="col-md-6 col-sm-12 col-12">
                              <b>{{ $t("SigninInvoiceAndBank.country") }}</b>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12">
                              {{ checkCountryFunc() }}
                            </div>
                          </div>
                          <hr />
                          <div class="row">
                            <div class="col-md-6 col-sm-12 col-12">
                              <b>{{ $t("SigninInvoiceAndBank.tax_id") }}</b>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12">
                              {{ itemInvoiceInfo.tax_number || "" }}
                            </div>
                          </div>
                          <hr />
                          <div class="row">
                            <div class="col-md-6 col-sm-12 col-12">
                              <b>{{ $t("SigninInvoiceAndBank.tel") }}</b>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12">
                              {{ itemInvoiceInfo.phone }}
                            </div>
                          </div>
                          <hr />
                          <div class="row">
                            <div class="col-md-6 col-sm-12 col-12">
                              <b>{{ $t("SigninInvoiceAndBank.finance_email") }}</b>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12">
                              {{ itemInvoiceInfo.email_finance }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row match-height">
                  <div class="col-md-12 col-12">
                    <div class="card" style="height: auto;">
                      <div class="card-content">
                        <div class="card-body" style="height: auto;">
                          <div class="row">
                            <span class="float-left text-info">
                              <v-icon color="info" class="ml-3 mr-2"
                                >mdi-email-outline</v-icon
                              >
                            </span>
                            {{ $t("SigninInvoiceAndBank.receive_by_email") }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else-if="!invoiceToActive"
                class="tab-pane active"
                id="checkout-tab"
                aria-expanded="true"
                role="tablist"
                aria-labelledby="checkout"
              >
                <div
                  v-if="!editBankDetailActive"
                  class="row match-height"
                  id="bankdetail"
                >
                  <div class="col-md-8 col-12">
                    <div class="card" style="height: auto;">
                      <div class="card-header-invoice">
                        <h2>
                          {{ $t("SigninInvoiceAndBank.receive_payout_form") }}
                          Aigent
                        </h2>
                        <span>{{ $t("SigninInvoiceAndBank.paid_to_your_bank") }}</span>
                      </div>
                      <hr />
                      <div class="card-content collapse show">
                        <div class="card-body" style="height: auto;">
                          <div class="row">
                            <div class="col-12">
                              <b>{{ $t("SigninInvoiceAndBank.bank_details") }}</b>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-6 col-sm-6 col-12">
                              {{ $t("SigninInvoiceAndBank.title_bank_name") }}
                            </div>
                            <div class="col-md-6 col-sm-6 col-12">
                              {{ checkFullBankNameFunc() }}
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-6 col-sm-6 col-12">
                              {{ $t("SigninInvoiceAndBank.account_number") }}
                            </div>
                            <div class="col-md-6 col-sm-6 col-12">
                              {{ bank_account_number }}
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-6 col-sm-6 col-12">
                              {{ $t("SigninInvoiceAndBank.account_holder_name") }}
                            </div>
                            <div class="col-md-6 col-sm-6 col-12">
                              {{ bank_account_name }}
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-6 col-sm-6 col-12">
                              {{ $t("SigninInvoiceAndBank.account_attach") }}
                            </div>
                            <div class="col-md-6 col-sm-6 col-12">
                              <v-icon>mdi-paperclip</v-icon>
                              <span>{{ old_file_account_attach }}</span>
                            </div>
                          </div>
                          <div v-if="checkRoleUserFunc() == 'provider'" class="row mt-1">
                            <div class="col-md-12 col-12">
                              <button
                                class="btn btn-info btn-min-width mb-1"
                                @click="editBankDetailActive = true"
                              >
                                {{ $t("SigninInvoiceAndBank.edit_invoice") }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="row match-height"
                  id="bankedit"
                  v-else-if="editBankDetailActive"
                >
                  <div class="col-md-12 col-12">
                    <div class="card">
                      <div class="card-header-invoice">
                        <h2>{{ $t("SigninInvoiceAndBank.btn_edit_bank_details") }}</h2>
                        <span>{{ $t("SigninInvoiceAndBank.paid_to_your_bank") }}</span>
                      </div>
                      <div class="card-content collapse show">
                        <div class="card-body" style="height: auto;">
                          <div class="row">
                            <div class="col-12">
                              <b>{{ $t("SigninInvoiceAndBank.bank_details") }}</b>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div
                              class="col-md-6 col-sm-6 col-12 d-flex flex-column justify-center"
                            >
                              <span>{{
                                $t("SigninInvoiceAndBank.title_bank_name")
                              }}</span>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12 pb-0">
                              <div
                                class="dropdown select"
                                @click="getfocus('selectbankname')"
                                @mouseleave="mouseOver('selectbankname')"
                              >
                                <a
                                  data-toggle="dropdown"
                                  data-display="static"
                                  id="selectbankname"
                                >
                                  <v-autocomplete
                                    v-model="edit_bankname"
                                    :items="itemsBankName"
                                    item-text="name"
                                    item-value="value"
                                    label
                                    placeholder
                                    :no-data-text="$t('SignupStep.nodata')"
                                    color="pink"
                                    solo
                                    flat
                                    dense
                                    single-line
                                    hide-details
                                    autocomplete="off"
                                    @mouseenter="getfocus('selectbankname')"
                                    @mouseleave="mouseOver('selectbankname')"
                                  ></v-autocomplete>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6 col-sm-6 col-12 pt-0"></div>
                            <div class="col-md-6 col-sm-12 col-12 pt-0">
                              <span class="error-text-input" v-if="!edit_bankname">{{
                                $t("Alert.pleasefilldata")
                              }}</span>
                            </div>
                          </div>

                          <div class="row mt-2">
                            <div
                              class="col-md-6 col-sm-6 col-12 d-flex flex-column justify-center"
                            >
                              <span>{{ $t("SigninInvoiceAndBank.account_number") }}</span>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12 pb-0">
                              <input
                                id="accountnumber"
                                ref="accountnumber"
                                type="text"
                                class="form-control"
                                name="accountnumber"
                                v-model="edit_account_number"
                                @keypress="isNumber($event)"
                                style="
                                  min-height: 2.625rem;
                                  border: 1px solid var(--border-gray);
                                "
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6 col-sm-6 col-12 pt-0"></div>
                            <div class="col-md-6 col-sm-12 col-12 pt-0">
                              <span
                                class="error-text-input"
                                v-if="!edit_account_number"
                                >{{ $t("Alert.pleasefilldata") }}</span
                              >
                            </div>
                          </div>

                          <div class="row mt-2">
                            <div
                              class="col-md-6 col-sm-6 col-12 d-flex flex-column justify-center"
                            >
                              <span>{{ $t("SigninInvoiceAndBank.fullname") }}</span>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12 pb-0">
                              <input
                                id="fullname"
                                ref="fullname"
                                type="text"
                                class="form-control"
                                name="fullname"
                                v-model="edit_fullname"
                                style="
                                  min-height: 2.625rem;
                                  border: 1px solid var(--border-gray);
                                "
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6 col-sm-6 col-12 pt-0"></div>
                            <div class="col-md-6 col-sm-12 col-12 pt-0">
                              <span class="error-text-input" v-if="!edit_fullname">{{
                                $t("Alert.pleasefilldata")
                              }}</span>
                            </div>
                          </div>

                          <div class="row mt-2">
                            <div
                              class="col-md-6 col-sm-6 col-12 d-flex flex-column justify-center"
                            >
                              <label class for="account_attach">{{
                                $t("SigninInvoiceAndBank.account_attach")
                              }}</label>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12">
                              <!-- <div
                                class="form-group"
                                @click="getfocus('account_attach')"
                                @mouseleave="mouseOver('account_attach')"
                              >
                                <a
                                  data-toggle
                                  data-display="static"
                                  id="account_attach"
                                  style="
                                    height: 3rem !important;
                                    border: 1px solid var(--border-gray);
                                  "
                                  class="form-control"
                                >
                                  <v-file-input
                                    v-if="edit_file_account_attach"
                                    v-model="edit_file_account_attach"
                                    accept="application/pdf, .pdf"
                                    show-size
                                    counter
                                    :label="$t('SigninInvoiceAndBank.account_attach')"
                                    color="pink"
                                    solo
                                    flat
                                    dense
                                    single-line
                                    hide-details
                                  ></v-file-input>
                                  <div
                                    v-else
                                    class="d-flex flex-row align-center justify-start"
                                  >
                                    <v-file-input
                                      v-model="edit_file_account_attach"
                                      accept="application/pdf, .pdf"
                                      hide-input
                                      color="pink"
                                      solo
                                      flat
                                      dense
                                      single-line
                                      hide-details
                                    ></v-file-input>
                                    <span
                                      style="position: absolute; left: 2.5rem"
                                      v-if="old_file_account_attach"
                                      >{{ old_file_account_attach }}</span
                                    >
                                    <span
                                      style="
                                        position: absolute;
                                        left: 2.5rem;
                                        color: #6c757d;
                                      "
                                      v-else
                                      >attach file (support .pdf file)</span
                                    >
                                  </div>
                                </a>
                              </div>
                              <span
                                class="error-text-input"
                                v-if="
                                  !edit_file_account_attach && !old_file_account_attach
                                "
                                >{{ $t("Alert.pleasefilldata") }}</span
                              > -->
                              <div
                                class="form-group"
                                @click="getfocus('account_attach')"
                                @mouseleave="mouseOver('account_attach')"
                              >
                                <a
                                  data-toggle
                                  data-display="static"
                                  id="account_attach"
                                  style="height: 3rem !important; border: none"
                                  class="form-control pl-0"
                                >
                                  <v-file-input
                                    v-if="edit_file_account_attach"
                                    v-model="edit_file_account_attach"
                                    accept="application/pdf, .pdf"
                                    show-size
                                    counter
                                    :label="$t('SigninInvoiceAndBank.account_attach')"
                                    color="pink"
                                    solo
                                    flat
                                    dense
                                    single-line
                                    hide-details
                                  ></v-file-input>
                                  <div
                                    v-else
                                    class="d-flex flex-row align-center justify-start"
                                  >
                                    <v-file-input
                                      v-model="edit_file_account_attach"
                                      accept="application/pdf, .pdf"
                                      hide-input
                                      color="pink"
                                      solo
                                      flat
                                      dense
                                      single-line
                                      hide-details
                                    ></v-file-input>
                                    <span
                                      style="position: absolute; left: 2rem"
                                      v-if="old_file_account_attach"
                                      >{{ old_file_account_attach }}</span
                                    >
                                    <span
                                      style="
                                        position: absolute;
                                        left: 2rem;
                                        color: #6c757d;
                                      "
                                      v-else
                                      >attach file (support .pdf file)</span
                                    >
                                  </div>
                                </a>
                              </div>
                              <span
                                class="error-text-input"
                                v-if="
                                  !edit_file_account_attach && !old_file_account_attach
                                "
                                >{{ $t("Alert.pleasefilldata") }}</span
                              >
                            </div>
                          </div>

                          <div class="row mt-2">
                            <div class="col-12 text-center">
                              <button
                                type="button"
                                class="btn btn-primary btn-min-width mb-1 mr-1 font-14px"
                                @click="cfSaveEditBankDetailsFunc()"
                                :disabled="savingBtn"
                              >
                                <i
                                  v-if="savingBtn"
                                  class="fa fa-circle-o-notch fa-spin"
                                ></i>
                                <span>
                                  {{ $t("SigninCalendar.btn_save_change") }}
                                </span>
                              </button>
                              <button
                                type="button"
                                class="btn btn-light btn-min-width mb-1 font-14px"
                                @click="initAll()"
                                :disabled="savingBtn"
                              >
                                {{ $t("SigninCalendar.btn_cancel") }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { EventBus } from "@/EventBus";
import swal from "sweetalert";

export default {
  name: "SigninInvoiceAndBankScreen",
  metaInfo: {
    title: "Invoice & Bank",
    titleTemplate: "%s | Aigent",
  },
  data: () => ({
    userToken: "",
    propertyID: "",
    value_element: "",
    invoiceID: "",
    invoiceToActive: true,
    editBankDetailActive: false,
    itemsBankName: [],
    edit_bankname: "",
    edit_account_number: "",
    edit_fullname: "",
    edit_file_account_attach: null,
    displayImg: "",
    itemInvoiceBank: {},
    bank_name: "",
    bank_account_number: "",
    bank_account_name: "",
    bank_attach: null,
    old_file_account_attach: "",
    itemInvoiceInfo: {},
    itemsDefaultCountry: [],
    itemsDefaultProvince: [],
    savingBtn: false,
    bankInvoiceID: "",
    userData: null,
    loadingData: false,
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.invoiceID = self.$route.params.invoiceId;
    // console.log(self.propertyID, self.invoiceID);
    self.initAll();
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("focus-2");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("focus-2");
        element.classList.add("focus-2");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("focus-2");
        element.classList.remove("focus-2");
        this.value_element = "";
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async initUserData() {
      const self = this;
      var temp = [];
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + "/my_user", {
          headers: {
            Authorization:
              self?.userToken ||
              localStorage?.getItem("Token") ||
              self?.$store?.state?.resSignUpData?.access_token,
            "X-Language-Code":
              localStorage?.getItem("language") || self?.$store?.state?.language,
          },
        });
        if (res?.status == 200) {
          self.userData = res?.data?.result || null;
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
      }
    },
    checkRoleUserFunc() {
      const self = this;
      // console.log('role: ', self.userData?.role)
      return self.userData?.role || "provider";
    },
    gotoEditInvoiceInfoFunc(id) {
      const self = this;
      var tmpId = id || self?.invoiceID;
      if (tmpId) {
        var item = {
          link: `finance/invoice_edit_info/${self.userToken}/${self.propertyID}/${tmpId}`,
          name: "SigninEditInvoiceInfoScreen",
          params: {
            userToken: self.userToken,
            propertyId: self.propertyID,
            invoiceId: tmpId,
          },
        };
      } else {
        var item = {
          link: `finance/invoice_edit_info/${self.userToken}/${self.propertyID}`,
          name: "SigninCreateInvoiceInfoScreen",
          params: {
            userToken: self.userToken,
            propertyId: self.propertyID,
          },
        };
      }
      EventBus.$emit("changePathname", item);
    },
    initAll() {
      const self = this;
      self.loadingData = true;
      self.editBankDetailActive = false;
      self.bank_name = "";
      self.bank_account_number = "";
      self.bank_account_name = "";
      self.bank_attach = null;
      self.old_file_account_attach = "";

      self.edit_bankname = "";
      self.edit_account_number = "";
      self.edit_fullname = "";
      self.initUserData();
      self.initCountry();
      self.initBankName();
      self.fetchInvoiceInfo();
      self.fetchInvoiceBank();
      setTimeout(() => {
        self.loadingData = false;
      }, 1500);
    },
    initBankName() {
      this.itemsBankName = [
        {
          value: "BBL",
          name: "ธนาคารกรุงเทพ จำกัด",
        },
        {
          value: "KTB",
          name: "ธนาคารกรุงไทย จำกัด",
        },
        {
          value: "KBANK",
          name: "ธนาคารกสิกรไทย จำกัด",
        },
        {
          value: "SCB",
          name: "ธนาคารไทยพาณิชย์ จำกัด",
        },
        {
          value: "TTB",
          name: "ธนาคารทหารไทยธนชาต จำกัด",
        },
        {
          value: "BAY",
          name: "ธนาคารกรุงศรีอยุธยา จำกัด",
        },
        {
          value: "GSB",
          name: "ธนาคารออมสิน",
        },
        {
          value: "UOB",
          name: "ธนาคารยูโอบี จำกัด",
        },
        {
          value: "GHB",
          name: "ธนาคารอาคารสงเคราะห์",
        },
        {
          value: "KKP",
          name: "ธนาคารเกียรตินาคินภัทร จำกัด",
        },
        {
          value: "CIMBT",
          name: "ธนาคาร ซี ไอ เอ็ม บี ไทย จำกัด",
        },
        {
          value: "TISCO",
          name: "ธนาคารทิสโก้ จำกัด",
        },
        {
          value: "SC",
          name: "ธนาคารสแตนดาร์ดชาร์เตอร์ด จำกัด",
        },
        {
          value: "LH",
          name: "ธนาคารแลนด์ แอนด์ เฮ้าส์ จำกัด",
        },
        {
          value: "ICBCT",
          name: "ธนาคารไอซีบีซี จำกัด",
        },
        {
          value: "BACC",
          name: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
        }
      ];
      this.itemsBankName.sort((a, b) => a.name.localeCompare(b.name, "th"));
    },
    checkFullBankNameFunc() {
      const self = this;
      const findBank = self?.itemsBankName?.find((p) => p.value == self.bank_name);
      return findBank?.name || self.bank_name || "";
    },
    async fetchInvoiceInfo() {
      const self = this;
      self.itemInvoiceInfo = {};
      var tmpInvoiceInfo = {};

      EventBus.$emit("loadingtillend");

      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/finance/invoice?property=${
              self?.propertyID || self?.$route?.params?.propertyId
            }`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          tmpInvoiceInfo = res?.data?.result || {};
          // console.log("info: ", tmpInvoiceInfo);
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error);
      } finally {
        self.itemInvoiceInfo = tmpInvoiceInfo || {};
        // console.log(self.itemInvoiceInfo);
        self.invoiceID = tmpInvoiceInfo?._id || "";
        EventBus.$emit("endloading");
      }
    },
    checkCountryFunc() {
      const self = this;
      const findValue = self?.itemsDefaultCountry?.find(
        (p) => p.value == self.itemInvoiceInfo.country
      );
      return findValue?.name || self.itemInvoiceInfo.country || "";
    },
    checkProvinceFunc() {
      const self = this;
      const findValue = self?.itemsDefaultProvince?.find(
        (p) => p.province_code == self.itemInvoiceInfo.province
      );
      return findValue?.province || self.itemInvoiceInfo.province || "";
    },
    checkAmphoeFunc() {
      const self = this;
      const findValue = self?.itemsDefaultProvince?.find(
        (p) =>
          p.province_code == self?.itemInvoiceInfo?.province &&
          p.amphoe_code == self.itemInvoiceInfo?.district
      );
      return (
        findValue?.amphoe || self?.itemInvoiceInfo?.district || "Prachuap Khiri Khan"
      );
    },
    checkDistrictFunc() {
      const self = this;
      const findValue = self?.itemsDefaultProvince?.find(
        (p) =>
          p.province_code == self.itemInvoiceInfo.province &&
          p.amphoe_code == self.itemInvoiceInfo.district &&
          p.district_code == self?.itemInvoiceInfo?.sub_district
      );
      return findValue?.district || self?.itemInvoiceInfo?.sub_district || "";
    },
    async initCountry() {
      const self = this;
      var tempCountry = [];
      var temp = [];
      self.itemsDefaultCountry = [];
      self.itemsDefaultProvince = [];
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + `/setting/country`, {
          headers: {
            Authorization:
              self?.userToken ||
              localStorage?.getItem("Token") ||
              self?.$store?.state?.resSignUpData?.access_token,
            "X-Language-Code":
              localStorage?.getItem("language") || self?.$store?.state?.language,
          },
        });
        if (res.status == 200) {
          if (res?.data?.result?.length) {
            res?.data?.result?.map((el) => {
              tempCountry.push({
                _id: el._id || 0,
                name: el.value || "ไทย",
                value: el._id || "th",
                province: el.province || [],
              });
              if (el?.province?.length) {
                el?.province?.map((ele) => {
                  temp.push({
                    district: ele?.subdistrict || "",
                    amphoe: ele?.district || "",
                    province: ele?.province || "",
                    zipcode: ele?.zipcode || "",
                    district_code: ele?.subdistrict_code || "",
                    amphoe_code: ele?.district_code || "",
                    province_code: ele?.province_code || "",
                  });
                });
              }
            });
          }
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      } finally {
        self.itemsDefaultCountry = tempCountry;
        self.itemsDefaultProvince = temp;
      }
    },
    async fetchInvoiceBank() {
      console.log("fetchInvoiceBank");
      const self = this;
      self.itemInvoiceBank = {};
      var tmpInvoiceBank = null;
      EventBus.$emit("loadingtillend");

      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/finance/invoice/bank?property=${self.propertyID}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        // console.log(res);
        if (res.status == 200) {
          tmpInvoiceBank = res?.data?.result || {};
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error);
      } finally {
        self.bank_name = tmpInvoiceBank?.bank_name || "";
        self.bank_account_number = tmpInvoiceBank?.bank_number || "";
        self.bank_account_name = tmpInvoiceBank?.name || "";
        self.bank_attach = tmpInvoiceBank?.file || null;
        self.old_file_account_attach = tmpInvoiceBank?.file || null;

        self.edit_bankname = tmpInvoiceBank?.bank_name || "";
        self.edit_account_number = tmpInvoiceBank?.bank_number || "";
        self.edit_fullname = tmpInvoiceBank?.name || "";
        self.bankInvoiceID = tmpInvoiceBank?._id || "";
      }
    },
    alertDimensionLarge() {
      swal(
        this.$t("Alert.title_imgtoolarge"),
        this.$t("Alert.label_imgtoolarge"),
        this.$t("Alert.warn_label"),
        {
          button: false,
          timer: 3000,
        }
      );
    },
    alertSizeLarge() {
      swal(
        this.$t("Alert.title_imgtoolarge"),
        this.$t("Alert.label_imgtoolargesize"),
        this.$t("Alert.warn_label"),
        {
          button: false,
          timer: 3000,
        }
      );
    },
    uploadFileHandler() {
      $("#fileUpload").trigger("click");
    },
    uploadFileChangeHandler(event) {
      const vm = this;
      let img = event.target.files;
      // console.log("img: ", event, img);
      if (img.length > 0) {
        for (let i = 0; i < img.length; i++) {
          const reader = new FileReader();
          reader.onload = (e) => {
            vm.displayImg = e.target.result;
          };
          reader.readAsDataURL(img[i]);
        }

        if (img[0]?.width > this.maxImageWidth || img[0].height > this.maxImageHeight) {
          vm.edit_file_account_attach = null;
          vm.displayImg = "";
          vm.alertDimensionLarge();
        } else if (img[0]?.size > 1024 * 1024 * 1 /*1MB*/) {
          vm.edit_file_account_attach = null;
          vm.displayImg = "";
          vm.alertSizeLarge();
        } else {
          vm.edit_file_account_attach = img[0];
        }
      } else {
        this.edit_file_account_attach = null;
      }
    },
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.displayImg = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    onFileChange(file) {
      // console.log(file);
      if (!file) {
        return;
      } else if (file?.width > this.maxImageWidth || file.height > this.maxImageHeight) {
        this.alertDimensionLarge();
        return;
      } else if (file?.size > 1024 * 1024 * 1 /*1MB*/) {
        this.alertSizeLarge();
        return;
      } else if (file !== "undefined") {
        this.edit_file_account_attach = file;
      }
      this.createImage(file);
    },
    isImgEmpty(path) {
      if (path) {
        return path;
      }
      return require("@/assets/icons/icon-noimage.png");
    },
    cfSaveEditBankDetailsFunc() {
      const self = this;
      if (!self.edit_bankname || !self.edit_account_number || !self.edit_fullname) {
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("Alert.pleasefill")}${
            !self.edit_bankname || self.edit_bankname == ""
              ? ` ${self.$t("SigninInvoiceAndBank.title_bank_name")}`
              : ""
          }${
            !self.edit_account_number || self.edit_account_number == ""
              ? ` ${self.$t("SigninInvoiceAndBank.account_number")}`
              : ""
          }${
            !self.edit_fullname || self.edit_fullname == ""
              ? ` ${self.$t("SigninInvoiceAndBank.fullname")}`
              : ""
          }`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else if (!self.edit_file_account_attach && !self.old_file_account_attach) {
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("Alert.pleasefilldata").replace("*", " ")}${self.$t(
            "SigninInvoiceAndBank.attach_file"
          )}`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else {
        var obj = {
          bank_name: self.edit_bankname,
          bank_number: self.edit_account_number,
          name: self.edit_fullname,
        };
        // console.log("obj: ", obj);
        self.confirmUploadFileAndDataFunc(obj);
      }
    },
    async confirmUploadFileAndDataFunc(obj) {
      const self = this;
      var filename = null;
      var formData = new FormData();
      if (self.edit_file_account_attach) {
        formData.append("file", self.edit_file_account_attach);
        filename = self.edit_file_account_attach.name;
      }
      EventBus.$emit("inProgressing");
      EventBus.$emit("loadingtillend");
      self.savingBtn = true;
      try {
        const res = await self.axios.post(
          process.env.VUE_APP_API + `/setting/upload`,
          formData,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
            // timeout: 3 * 60
          }
        );
        // console.log("upload: ", res);
      } catch (error) {
        EventBus.$emit("endloading");
        EventBus.$emit("endProgress");
        console.log(error);
        self.savingBtn = false;
      } finally {
        // console.log("filename: ", self.edit_file_account_attach, filename);
        self.confirmSave(obj, filename);
        self.savingBtn = false;
      }
    },
    async confirmSave(obj, filename) {
      const self = this;
      if (filename) obj.file = filename;
      EventBus.$emit("loadingtillend");
      EventBus.$emit("inProgressing");
      self.savingBtn = true;
      // console.log("confirm: ", obj);
      if (self.bankInvoiceID) {
        try {
          const res = await self.axios.put(
            process.env.VUE_APP_API +
              `/finance/invoice/bank?invoice_bank_id=${self?.bankInvoiceID}`,
            obj,
            {
              headers: {
                Authorization: self?.userToken,
                "X-Language-Code":
                  localStorage?.getItem("language") || self?.$store?.state?.language,
              },
            }
          );
          // console.log("update: ", res);
          if (res?.status == 200) {
            self.savingBtn = false;
            EventBus.$emit("endloading");
            EventBus.$emit("endProgress");
            swal(
              self.$t("Alert.success_title"),
              self.$t("Alert.save_success"),
              self.$t("Alert.success_label"),
              {
                button: false,
                timer: 3000,
              }
            ).then(() => {
              self.editBankDetailActive = false;
              self.initAll();
            });
          }
        } catch (error) {
          self.savingBtn = false;
          EventBus.$emit("endloading");
          EventBus.$emit("endProgress");
          console.log(error);
          swal(
            self.$t("Alert.error_title"),
            error?.response?.data?.message || self.$t("Alert.tryagain"),
            self.$t("Alert.error_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        } finally {
          self.savingBtn = false;
          EventBus.$emit("endloading");
          EventBus.$emit("endProgress");
        }
      } else {
        try {
          const res = await self.axios.post(
            process.env.VUE_APP_API +
              `/finance/invoice/bank?property_id=${self.propertyID}`,
            obj,
            {
              headers: {
                Authorization: self?.userToken,
                "X-Language-Code":
                  localStorage?.getItem("language") || self?.$store?.state?.language,
              },
            }
          );
          // console.log("new: ", res);
          if (res?.status == 200) {
            self.savingBtn = false;
            EventBus.$emit("endloading");
            EventBus.$emit("endProgress");
            swal(
              self.$t("Alert.success_title"),
              self.$t("Alert.save_success"),
              self.$t("Alert.success_label"),
              {
                button: false,
                timer: 3000,
              }
            ).then(() => {
              self.editBankDetailActive = false;
              self.initAll();
            });
          }
        } catch (error) {
          self.savingBtn = false;
          EventBus.$emit("endloading");
          EventBus.$emit("endProgress");
          console.log(error);
          swal(
            self.$t("Alert.error_title"),
            error?.response?.data?.message || self.$t("Alert.tryagain"),
            self.$t("Alert.error_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        } finally {
          self.savingBtn = false;
          EventBus.$emit("endloading");
          EventBus.$emit("endProgress");
        }
      }
    },
  },
};
</script>

<style scoped>
@import "../../../../styles/signin/holiday.css";
@import "../../../../styles/signin/rateplan.css";
@import "../../../../styles/signin/finance.css";
@import "../../../../styles/signin/invoiceandbank.css";
</style>

<style lang="scss" scoped>
@import "../../../../styles/bootstrap/global.css";
@import "../../../../styles/signin/setting.css";
@import "../../../../styles/bootstrap/bootstrap.css";
@import "../../../../styles/bootstrap/colors.css";

.border-pink-focus {
  border-color: var(--pink) !important;
}
</style>
